<script>
export default {
  name: 'base-switch',
  props: {
    value: {
      type: [Number, String, Object, Boolean],
      default: '',
    },
    values: {
      type: [Array, Object],
      default: () => [],
    },
    keyProp: {
      type: String,
      default: 'value',
    },
    showProp: {
      type: String,
      default: 'label',
    },
    look: {
      type: String,
      default: 'primary',
      validator: (v) => !v || /primary|secondary|recroom|material/.test(v),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    select(option) {
      if (option.disabled) return
      this.$emit('input', option.value)
    },
    isActive(option) {
      return option[this.keyProp] === this.value
    },
  },
}
</script>

<template>
  <div
    :class="
      look === 'recroom'
        ? 'flex overflow-hidden rounded-md border-2 border-primary'
        : 'inline-flex min-h-10 rounded-sm'
    "
  >
    <div
      v-for="option in values"
      :key="option[keyProp]"
      ref="option"
      class="relative z-1 flex flex-shrink flex-grow basis-full items-center justify-center text-center text-sm font-bold leading-5 duration-200"
      :class="[
        disabled || option.disabled ? 'opacity-75' : 'cursor-pointer',
        look === 'primary' && 'first:rounded-l last:rounded-r last:border-r',
        look === 'primary' && !isActive(option) && 'text-eonx-neutral-800',
        look === 'primary' &&
          isActive(option) &&
          'border-primary bg-primary-lightest text-primary opacity-60',
        look === 'secondary' && '-mr-px first:rounded-l last:rounded-r',
        look === 'secondary' &&
          isActive(option) &&
          'z-10 border-primary bg-primary-lightest text-primary',
        look !== 'recroom' && 'bg-white px-[5px]',
        look === 'recroom' && !isActive(option) && 'bg-gray-50 text-primary',
        look === 'recroom' && isActive(option) && 'bg-primary text-white',
        look !== 'material' && look !== 'recroom' && 'border border-solid',
        look === 'material' &&
          'rounded-none border-0 border-b-2 p-2.5 text-primary',
        look === 'material' && isActive(option) && 'border-primary bg-white',
      ]"
      @click="!disabled && select(option)"
    >
      <slot v-if="$scopedSlots.option" :option="option" name="option" />
      <template v-else>
        {{ option[showProp] }}
      </template>
    </div>
  </div>
</template>
